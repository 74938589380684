import { Badge, Button, Tooltip } from 'antd'
import search_img from 'assets/images/icons/search.svg'
import account_img from 'assets/images/icons/account.svg'
import account_b_img from 'assets/images/icons/account_b.svg'
import search_b_img from 'assets/images/icons/search_b.svg'
import cartImg from 'assets/images/icons/cart.svg'
import carBtImg from 'assets/images/icons/cart-b.svg'
import logo_b_img from 'assets/images/naseej-black.png'
// import logo_img from 'assets/images/logo.png'
import logo_img from 'assets/images/Naseej-white.png'

import { useState } from 'react'
import cls from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import SideMenu from './SideMenu/SideMenu'
import menu_img from 'assets/images/icons/menu.svg'
import menu_b_img from 'assets/images/icons/menu_b.svg'
import styles from './Header.module.scss'
import useHeaderFixedStyle from 'hooks/useHeaderFixedStyle'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import OwnOverlay from 'components/own/OwnOverlay/OwnOverlay'
import UserMenu from './UserMenu'
import useCatList from 'api-hooks/categories/useCatList'
import useCartProdList from 'api-hooks/cart/useCartProdList'
import useLayout from 'hooks/useLayout'
import { useSelector } from 'react-redux'

function Header({ light }) {
  const navigate = useNavigate()
  const { isSmlScr } = useLayout()
  const { itemsCount } = useCartProdList()
  // console.log('Header ~ itemsCount', itemsCount)

  const user = useSelector(s => s.entities?.user?.currentUser)

  // console.log(user)

  const { t, i18n } = useTranslation()
  const nextLang = i18n.language === 'en' ? 'ar' : 'en'
  const isLtr = i18n.language !== 'ar'
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isOverlay, setIsOverlay] = useState(false)
  const { headerStyle, isFixed } = useHeaderFixedStyle()
  const { catList } = useCatList()
  // console.log(catList)

  const handleChangeLang = () => {
    i18n.changeLanguage(nextLang)
    localStorage.setItem('user-lang', JSON.stringify(nextLang))
    window.location.reload()
  }

  return (
    <>
      <div
        className={cls(
          styles.header_wrapper,
          { [styles.light]: light },
          { [styles.fixed]: isFixed }
        )}
        style={headerStyle}
      >
        <div className="container">
          <div className={styles.header_inner}>
            <div className={styles.side_menu_wrapper}>
              <Tooltip title={t('menu')} placement="bottomRight">
                <Button
                  type="link"
                  icon={
                    <motion.img
                      src={light ? menu_img : menu_b_img}
                      alt="menu"
                      width={28}
                      animate={
                        isShowMenu
                          ? { rotate: 90, scale: 1.2 }
                          : { rotate: 0, scale: 1 }
                      }
                    />
                  }
                  onClick={() => {
                    setIsShowMenu(prev => !prev)
                    setIsOverlay(prev => !prev)
                  }}
                />
              </Tooltip>
              {isShowMenu && (
                <motion.div
                  animate={{ x: isLtr ? 60 : -60 }}
                  className={styles.menu_wrapper}
                >
                  <SideMenu catList={catList} setIsShowMenu={setIsShowMenu} />
                </motion.div>
              )}
              {!isSmlScr && (
                <Tooltip title={t('search')} placement="bottomRight">
                  <Button
                    type="link"
                    icon={
                      <img
                        src={light ? search_img : search_b_img}
                        alt="search"
                        width={24}
                      />
                    }
                    onClick={() => navigate('/product-search')}
                  />
                </Tooltip>
              )}
            </div>

            <div className={styles.brand_wrapper}>
              <Link to="/">
                <img src={light ? logo_img : logo_b_img} alt="naseej" />
              </Link>
            </div>

            <div className={styles.user_actions_wrapper}>
              {/* <div className={styles.user_search}> */}
              {/* <Tooltip title={t('search')} placement="bottomRight">
                <Button
                  type="link"
                  icon={
                    <img
                      src={light ? search_img : search_b_img}
                      alt="search"
                      width={24}
                    />
                  }
                  onClick={() => navigate('/product-search')}
                />
              </Tooltip> */}
              {isSmlScr && (
                <Tooltip title={t('search')} placement="bottomRight">
                  <Button
                    type="link"
                    icon={
                      <img
                        src={light ? search_img : search_b_img}
                        alt="search"
                        width={24}
                      />
                    }
                    onClick={() => navigate('/product-search')}
                  />
                </Tooltip>
              )}
              {!!user && (
                <Tooltip title={t('your cart')} placement="bottomRight">
                  <Badge count={itemsCount} size="small">
                    <Button
                      type="link"
                      icon={
                        <img
                          src={light ? carBtImg : cartImg}
                          alt="your cart"
                          width={24}
                        />
                      }
                      onClick={() => navigate('/cart')}
                    />
                  </Badge>
                </Tooltip>
              )}
              {/* </div> */}
              <div className={styles.user_account}>
                {user ? (
                  <UserMenu light={light} />
                ) : (
                  <Tooltip title={t('login')} placement="bottomRight">
                    <Link to="/signin">
                      <img
                        src={light ? account_img : account_b_img}
                        alt="menu"
                        width={24}
                      />
                    </Link>
                  </Tooltip>
                )}
              </div>
              {/* <div className={cls(styles.user_lang, { [styles.light]: light })}>
                <Tooltip title={t('changeLanguage')} placement="bottomRight">
                  <Button type="text" onClick={handleChangeLang}>
                    {nextLang}
                  </Button>
                </Tooltip>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <OwnOverlay
        visible={isOverlay && isShowMenu}
        onClick={() => {
          setIsShowMenu(false)
          setIsOverlay(false)
        }}
      />
    </>
  )
}
export default Header
