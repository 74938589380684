import { Menu } from 'antd'
import styles from './SideMenu.module.scss'
import useIsScreenGreater from 'hooks/useIsScreenGreater'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { t } from 'i18next'

const bigScreenStart = 800
//
function SideMenu({ setIsShowMenu, catList }) {
  const isScreenGreater = useIsScreenGreater(bigScreenStart)
  const navigate = useNavigate()
  const [openKeys, setOpenKeys] = useState([])

  const rootSubmenuKeys = catList?.map(root => root?.id.toString()) || []
  // console.log(rootSubmenuKeys)

  // console.log('openKeys', openKeys)
  const onOpenChange = keys => {
    // console.log('keys', keys)
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    // console.log('latestOpenKey', latestOpenKey)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
      // console.log('setOpenKeys', keys)
    } else {
      setOpenKeys(latestOpenKey ? ['product catalogue', latestOpenKey] : [])
    }
  }

  function getItem(label, key, children, type) {
    return {
      label,
      key,
      children,
      type,
    }
  }
  const items = [
    getItem(
      t('Shop Products'),
      'product catalogue',
      catList?.map(item => {
        if (!item?.children?.length >= 1) return null

        return getItem(
          item?.name,
          item?.id,
          item?.children?.length
            ? item?.children?.map(sub =>
                // getItem(
                //   sub?.name,
                //   `/product-catalogue/${item?.name}/${sub?.id}/${sub?.name}`
                // )
                {
                  // console.log('cat item>>>>', item)
                  return getItem(
                    sub?.name,
                    `/product-catalogue/${item?.id}/${item?.name}/${sub?.id}/${sub?.name}/details`
                    // product-catalogue/9/Textiles/17/“All-Natural”%20Textiles/details
                  )
                }
              )
            : null
        )
      })
    ),
    getItem(t('Mood boards'), 'moodboards'),
    getItem(t('collections'), 'collections'),
    getItem(t('our story'), 'our-story'),
  ]

  const onClick = e => {
    // console.log('click ', e)
    // setCurrent(e.key);
    navigate(e.key)
    setIsShowMenu(false)
  }

  return (
    <Menu
      onClick={onClick}
      className={styles.menu_hero}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      mode={isScreenGreater ? 'vertical' : 'inline'}
      items={items}
    />
  )
}
export default SideMenu
