import useSearchQuery from 'hooks/useSearchQuery'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
  const { searchQueryStr } = useSearchQuery()
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }, [searchQueryStr])

  return null
}
export default ScrollToTop
