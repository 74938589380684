import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ar from './translations/ar'
import en from './translations/en'

let currentLang = localStorage.getItem('user-lang')
if (currentLang) {
  currentLang = JSON.parse(currentLang)
} else {
  currentLang = localStorage.setItem('user-lang', JSON.stringify('en'))
}

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
