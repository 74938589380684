// import { useState } from 'react'

const { default: useIsScreenGreater } = require('hooks/useIsScreenGreater')
const { createContext } = require('react')

export const layout = createContext({})

function LayoutProvider({ children }) {
  const isBigScr = useIsScreenGreater(1200)
  const isMidScr = useIsScreenGreater(992)
  const isSmlScr = useIsScreenGreater(768)
  // const [isAppointmentPopup, setIsAppointmentPopup] = useState(false)

  // const openAppointmentPopup = () => {
  //   const isAppointmentPopupShown = sessionStorage.getItem(
  //     'isAppointmentPopupShown'
  //   )
  //   if (isAppointmentPopupShown) return
  //   setIsAppointmentPopup(true)
  //   sessionStorage.setItem('isAppointmentPopupShown', true)
  // }
  // const closeAppointmentPopup = () => {
  //   setIsAppointmentPopup(false)
  // }

  return (
    <layout.Provider
      value={{
        isBigScr,
        isMidScr,
        isSmlScr,
        // isAppointmentPopup,
        // openAppointmentPopup,
        // closeAppointmentPopup,
      }}
    >
      {children}
    </layout.Provider>
  )
}

export default LayoutProvider
