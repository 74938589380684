import { initializeApp } from 'firebase/app'
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}
// const firebaseConfig = {
//   apiKey: 'AIzaSyA49uwUHRSLKAmUmAFUzhF8mHLbE1F6YEM',
//   authDomain: 'naseej-development.firebaseapp.com',
//   projectId: 'naseej-development',
//   storageBucket: 'naseej-development.appspot.com',
//   messagingSenderId: '171771544732',
//   appId: '1:171771544732:web:e7ef4a9c0d6a489944d959',
// }

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
