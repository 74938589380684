import React from 'react'
import ReactDOM from 'react-dom/client'
import 'services/localization/i18n'
import App from './App'
import store from './store/configureStore'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
let persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <Router>
      {/* <React.StrictMode> */}
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
      {/* </React.StrictMode> */}
    </Router>
  </Provider>
)
