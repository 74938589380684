import { message } from 'antd'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
  updateEmail,
  signOut as signOutFromFirebase,
} from 'firebase/auth'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import useAuth from 'services/api/auth/useAuth'
import { auth } from 'services/firebase/firebase'

const firebaseToken = process.env.REACT_APP_API_KEY

function useFirebase() {
  const navigate = useNavigate()
  const { signinWithProvider } = useAuth()

  const onSigninWithProvider = async (provider, accessToken) => {
    const fd = new FormData()
    fd.append('from', provider)
    fd.append('token', accessToken)
    fd.append('firebaseToken', firebaseToken)
    fd.append('deviceType', 'Web')

    const res = await signinWithProvider(fd)
    // console.log('onSigninWithProvider  res:', res)
    if (res?.code === 200) navigate('/')

    return res
  }

  const signInWithEmailAndPassword = async (email, password) => {
    try {
      // const email = prompt('email:')
      // const password = prompt('password:')
      const result = await createUserWithEmailAndPassword(auth, email, password)
      // console.log('signInWithEmailAndPassword  result:', result)
      return result
    } catch (error) {
      // console.log('signInWithEmailAndPassword  error:', { error })
      if (error.code === 'auth/email-already-in-use') {
        message.error('The email address is already in use')
      } else if (error.code === 'auth/invalid-email') {
        message.error('The email address is not valid.')
      } else if (error.code === 'auth/operation-not-allowed') {
        message.error('Operation not allowed.')
      } else if (error.code === 'auth/weak-password') {
        message.error('The password is too weak.')
      }
    }
  }

  const signInWithGoogle = async () => {
    try {
      const googleProvider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, googleProvider)
      // console.log('signInWithGoogle  result:', result)

      const credential = GoogleAuthProvider.credentialFromResult(result)
      // console.log('signInWithGoogle  credential', credential)
      const accessToken = credential.accessToken
      // console.log('.then ~ accessToken', accessToken)

      onSigninWithProvider('google', accessToken)

      return result
    } catch (error) {
      // console.log('signInWithGoogle error:', { error })
      message.error(
        t(
          'The provider is not valid with this email, please try another login method'
        )
      )
    }
  }

  const signInWithFacebook = async () => {
    try {
      const facebookProvider = new FacebookAuthProvider()
      const result = await signInWithPopup(auth, facebookProvider)
      // console.log('signInWithFacebook  result:', result)

      const credential = FacebookAuthProvider.credentialFromResult(result)
      // console.log('signInWithFacebook  credential', credential)

      const accessToken = credential.accessToken
      onSigninWithProvider('facebook', accessToken)

      return result
    } catch (error) {
      // console.log('signInWithFacebook  error:', { error })
      message.error(
        t(
          'The provider is not valid with this email, please try another login method'
        )
      )

      // const email = error?.customData?.email
      // const password = window.prompt('Please provide the password for ' + email)
      // console.log({ email, password })
      // signInWithEmailAndPassword(email, password)
    }
  }
  // const signInWithFacebook = async () => {
  //   try {
  //     const facebookProvider = new FacebookAuthProvider()
  //     const result = await signInWithPopup(auth, facebookProvider)
  //     console.log('signInWithFacebook  result:', result)

  //     const credential = FacebookAuthProvider.credentialFromResult(result)
  //     console.log('signInWithFacebook  credential', credential)

  //     const accessToken = credential.accessToken
  //     if (result?.user?.email) {
  //       onSigninWithProvider('facebook', accessToken)
  //     } else {
  //       const email = window.prompt(
  //         `Hello ${result?.user?.displayName} please enter email`
  //       )
  //       if (email) {
  //         try {
  //           const result = await updateEmail(auth.currentUser, email)
  //           console.log('updateEmail  result:', result)
  //           onSigninWithProvider('facebook', accessToken)
  //         } catch (error) {
  //           console.log('signInWithFacebook  error:', { error })
  //         }
  //       }
  //     }

  //     return result
  //   } catch (error) {
  //     console.log('signInWithFacebook  error:', { error })
  //     message.error(
  //       t(
  //         'The provider is not valid with this email, please try another login method'
  //       )
  //     )

  //     // const email = error?.customData?.email
  //     // const password = window.prompt('Please provide the password for ' + email)
  //     // console.log({ email, password })
  //     // signInWithEmailAndPassword(email, password)
  //   }
  // }

  const signOutFirebase = async () => {
    try {
      const result = await signOutFromFirebase(auth)
      console.log('signOutFirebase  result:', result)
      return result
    } catch (error) {
      console.log('signOut  error:', { error })
    }
  }

  return {
    signInWithEmailAndPassword,
    signInWithGoogle,
    signInWithFacebook,
    signOutFirebase,
  }
}

export default useFirebase
