import React from 'react'
import LayoutProvider from './layout'
import ServicesContext from './services'

function ContextProvider({ children }) {
  return (
    <ServicesContext>
      <LayoutProvider>{children}</LayoutProvider>
    </ServicesContext>
  )
}

export default ContextProvider
