import { layout } from 'context/layout'
import { useContext } from 'react'

function useLayout() {
  const {
    isBigScr,
    isMidScr,
    isSmlScr,
    isAppointmentPopup,
    openAppointmentPopup,
    closeAppointmentPopup,
  } = useContext(layout)
  return {
    isBigScr,
    isMidScr,
    isSmlScr,
    isAppointmentPopup,
    openAppointmentPopup,
    closeAppointmentPopup,
  }
}

export default useLayout
