import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { getStoredToken } from 'services/localStorageUtils'

//  START FUNCTION
function useApi(config = {}) {
  const { i18n } = useTranslation()

  // const token = user?.token

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      'content-language': i18n.language,
      // Authorization: `Bearer ${token}`,
    },
    ...config,
  })

  axiosInstance.interceptors.request.use(config => {
    const token = getStoredToken()
    console.log('useApi  token:', token)
    if (token) config.headers.Authorization = 'Bearer ' + token

    return config
  })
  // ############################
  async function get(route) {
    const { data } = await axiosInstance.get(route)
    return data
  }

  async function post(route, body) {
    const { data } = await axiosInstance.post(route, body)
    return data
  }

  async function put(route, body) {
    const { data } = await axiosInstance.put(route, body)
    return data
  }

  async function patch(route, body) {
    const { data } = await axiosInstance.patch(route, body)
    return data
  }

  async function del(route) {
    const { data } = await axiosInstance.delete(route)
    return data
  }

  // ############################
  return { get, post, put, patch, delete: del }
}

export default useApi
