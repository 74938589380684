import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'services/api/react-query/queryKeys'
import useApi from 'services/api/useApi'

function useCatList() {
  const api = useApi()
  const http = async () => await api.get('/categories')
  const { data, isLoading } = useQuery([queryKeys.categoriesList], http)
  // console.log('useCatList  data', data)

  const catList = data?.data?.categories

  return { catList, catListLod: isLoading }
}

export default useCatList
