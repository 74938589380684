import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "carts",
  initialState: {
    order : null
  },
  reducers: {
    setUserOrder: (user, action) => {
      user.order = action.payload; 
    },
  },
});

export const {
  setUserOrder,
} = slice.actions;

export default slice.reducer;
