export const queryKeys = {
  user: 'user',
  areaList: 'area-list',
  profile: 'profile',
  homeData: 'home-data',
  moodBoardsList: 'mood-boards-list',
  singleMoodBoard: 'single-mood-board',
  personalMBList: ' personal-mood-boards-list',
  singlePersonalMB: 'single-personal-mood-board',
  products: 'products',
  productsItem: 'products-item',
  cartProductList: 'cart-product-list',
  cartPaymentList: 'cart-payment-list',
  orderList: 'order-list',
  orderDetails: 'order-details',
  orderSummary: 'order-summary',
  addressList: 'address-list',
  paymentList: 'payment-list',
  areasList: 'areas-list',
  categoriesList: 'categories-list',
  categoriesProductList: 'categories-product-list',
  collectionsList: 'collections-list',
  collectionItem: 'collections-item',
  notificationsList: 'notifications-list',
  productFilteration: 'product-filteration',
  categoryProductFilter: 'category-product-filter',
  favoriteList: 'favorite-list',
  moodbordSharedLink: 'moodbord-shared-link',
  productSearch: 'product-search',
}
