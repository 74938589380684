import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-toastify/dist/ReactToastify.css'
import './styles/antd/antd.css'
import './styles/globals.scss'
import { ConfigProvider } from 'antd'
import { LayoutConfig } from 'services/util/LayoutConfig'
import Routes from './Routes'
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import { useTranslation } from 'react-i18next'
import ScrollToTop from 'common/ScrollToTop'
import QueryProvider from 'services/api/react-query'
import { ToastContainer } from 'react-toastify'
import Loading from 'common/Loading/Loading'
import OwnProgress from 'components/own/OwnProgress/OwnProgress'
import ContextProvider from 'context'
import React from 'react'
// import AppointmentPopup from 'components/AppointmentPopup/AppointmentPopup'

function App() {
  const { i18n } = useTranslation()

  return (
    <QueryProvider>
      <ContextProvider>
        <ConfigProvider direction={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
          <OwnProgress>
            <LayoutConfig
              headerElement={Header}
              footerElement={Footer}
              htmlLang={i18n.language}
            >
              <React.Suspense fallback={<Loading spinning />}>
                <Routes />
              </React.Suspense>
              {/* <AppointmentPopup /> */}
            </LayoutConfig>
            <Loading />
          </OwnProgress>
          <ToastContainer
            style={{ fontSize: '1.4rem' }}
            // position="top-center"
          />
          <ScrollToTop />
        </ConfigProvider>
      </ContextProvider>
    </QueryProvider>
  )
}

export default App
