import { message } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearStoredToken, setStoredToken } from 'services/localStorageUtils'
import { setCurrentUser } from 'store/reducers/user'
import useApi from '../useApi'

function useAuth() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const api = useApi()
  const [authLoading, setAuthLoading] = useState(false)

  async function authServerCall(endpoint, data = {}, noMsg) {
    try {
      setAuthLoading(true)
      const res = await api.post(endpoint, data)
      // console.log('authServerCall  res', res)
      setAuthLoading(false)
      if (!noMsg) res?.validation?.forEach(msg => toast.error(msg))

      return res
    } catch (error) {
      // console.log('authServerCall  error', error)
      setAuthLoading(false)
    }
  }
  //
  async function signup({ fd, onSuc }) {
    const res = await authServerCall('/register', fd)

    if (res.code === 200) {
      const userData = res?.data.user
      const token = res?.message
      const user = { ...userData, token }

      // updateUser(user)
      setCurrentUser(user)
      toast.success(t('welcome') + ' ' + user.first_name)
      if (onSuc) onSuc(res)

      return true
    }
  }

  async function resendCode(formData) {
    const res = await authServerCall('/resend/code', formData)

    if (res.code === 200) {
      navigate('/enter-code', { state: { userId: res.item } })
      toast.success(res?.message)
      return null
    }
    return null
  }

  async function verifyCode(formData) {
    const res = await authServerCall('/verify/registration/code', formData)

    if (res.code === 200) {
      const userData = res?.data.user
      const token = res?.message
      setStoredToken(token)
      const user = { ...userData, token }

      dispatch(setCurrentUser(user))
      toast.success(t('welcome') + ' ' + user.first_name)

      return true
    }
    return false
  }

  async function signin({ fd, onSuc }) {
    const res = await authServerCall('/login', fd, true)

    if (res.code === 200) {
      const userData = res?.data.user
      const token = res?.message
      const user = { ...userData, token }
      setStoredToken(token)

      dispatch(setCurrentUser(user))
      toast.success(t('welcome back') + ' ' + user.first_name)

      if (onSuc) onSuc(res)
      return true
    }

    toast.error(t('Phone number or password is incorrect.'))
    return false
  }
  async function signinWithProvider(formData) {
    const res = await authServerCall('/login/provider', formData, 'noMsg')
    if (res.code === 200) {
      const userData = res?.data.user
      const token = res?.message
      const user = { ...userData, token }
      setStoredToken(token)

      dispatch(setCurrentUser(user))
      toast.success(t('welcome name', { name: user.first_name }))
    }

    res?.validation?.forEach(msg => {
      if (msg === 'Please register with social account having an email') {
        message.error(
          t(
            'The provider is not valid with this email, please try another login method'
          )
        )
      } else {
        message.error(msg)
      }
    })

    return res
  }

  async function signout() {
    // clearUser()
    clearStoredToken()
    dispatch(setCurrentUser(null))
    navigate('/', { replace: true })
    toast.info(t('Signed out'))
    const res = await authServerCall('/logout')
    return res
  }

  async function forgotPassword(formData) {
    const res = await authServerCall('/forgot/password', formData)
    if (res.code === 200) {
      toast.success(t('Verification code has been sent'))

      return res.item
    }

    return false
  }

  async function resetPassword(formData) {
    const res = await authServerCall('/reset/forgot/password', formData)
    if (res.code === 200) {
      const userData = res?.data.user
      const token = res?.message
      const user = { ...userData, token }
      setStoredToken(token)

      dispatch(setCurrentUser(user))
      toast.success(t('welcome back') + ' ' + user.first_name)

      return true
    }

    toast.error(t('something went wrong! try to signin with normal way.'))
    return false
  }

  return {
    signin,
    signinWithProvider,
    signup,
    signout,
    verifyCode,
    resendCode,
    forgotPassword,
    resetPassword,
    authLoading,
  }
}

export default useAuth
