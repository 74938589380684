import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "ui",
  initialState: {
    layout: {
      header: { status: true, darkTheme: false, bgDark: false },
      footer: { status: true },
    },
    showMenu: false,
  },
  reducers: {
    setLayout: (ui, action) => {
      ui.layout = {
        header: { ...ui.layout.header, ...action.payload.header },
        footer: { ...ui.layout.footer, ...action.payload.footer },
      };
    },
    setLayoutNoFooterHeader : (ui, action) => {
      ui.layout = {
        header: { status: false, darkTheme: false, bgDark: false },
        footer: { status: false },
      };
    },
    resetLayout: (ui, action) => {
      ui.layout = {
        header: { status: true, darkTheme: false, bgDark: false },
        footer: { status: true },
      };
    },
    setShowMenu: (ui, action) => {
      ui.showMenu = action.payload;
    },
  },
});

export const { setLayout, resetLayout, setShowMenu, setLayoutNoFooterHeader } = slice.actions;
export default slice.reducer;
