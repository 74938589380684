import React from 'react'
import { useRoutes } from 'react-router-dom'
const EnterCode = React.lazy(() => import('pages/EnterCode/EnterCode'))
const EnterEmail = React.lazy(() => import('pages/EnterEmail/EnterEmail'))
const NewPassword = React.lazy(() => import('pages/NewPassword/NewPassword'))
const Signin = React.lazy(() => import('pages/Signin/Signin'))
const Signup = React.lazy(() => import('pages/Signup/Signup'))
const VerifiedDone = React.lazy(() => import('pages/VerifiedDone/VerifiedDone'))
const Home = React.lazy(() => import('./pages/Home/Home'))
const OwnNotFound = React.lazy(() =>
  import('components/own/OwnNotFound/OwnNotFound')
)
const Collections = React.lazy(() => import('pages/Collections/Collections'))
const CollectionDetails = React.lazy(() =>
  import('pages/CollectionDetails/CollectionDetails')
)
const Product = React.lazy(() => import('pages/Product/Product'))
const Cart = React.lazy(() => import('pages/Cart/Cart'))
const AddressSelect = React.lazy(() =>
  import('pages/AddressSelect/AddressSelect')
)
const PaymentSelect = React.lazy(() =>
  import('pages/PaymentSelect/PaymentSelect')
)
const OrderReview = React.lazy(() => import('pages/OrderReview/OrderReview'))
const OrderPlace = React.lazy(() => import('pages/OrderPlace/OrderPlace'))
const Moodboards = React.lazy(() => import('pages/Moodboards/Moodboards'))
const CategoryMB = React.lazy(() =>
  import('pages/Moodboards/CategoryMB/CategoryMB')
)
const CreateMB = React.lazy(() => import('pages/Moodboards/CreateMB/CreateMB'))
const SearchMB = React.lazy(() => import('pages/Moodboards/SearchMB/SearchMB'))
const UserPortal = React.lazy(() => import('pages/UserPortal/UserPortal'))
const ProductCatalogue = React.lazy(() =>
  import('pages/ProductCatalogue/ProductCatalogue')
)
const SearchProduct = React.lazy(() =>
  import('pages/SearchProduct/SearchProduct')
)
const OurStory = React.lazy(() => import('pages/OurStory/OurStory'))
const SharedMbd = React.lazy(() =>
  import('pages/Moodboards/SharedMbd/SharedMbd')
)
const TermsAndConditions = React.lazy(() =>
  import('pages/TermsAndConditions/TermsAndConditions')
)
const CheckoutRedirect = React.lazy(() =>
  import('pages/CheckoutRedirect/CheckoutRedirect')
)

function RoutesWrapper() {
  // const [authenticated] = useState(false)

  const routes = useRoutes([
    {
      path: '*',
      element: <OwnNotFound />,
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: 'signup',
      element: <Signup />,
    },
    {
      path: 'signin',
      element: <Signin />,
    },
    {
      path: 'enter-email',
      element: <EnterEmail />,
    },
    {
      path: 'enter-code',
      element: <EnterCode />,
    },
    {
      path: 'new-password',
      element: <NewPassword />,
    },
    {
      path: 'verified-done',
      element: <VerifiedDone />,
    },
    // ==================================== //
    {
      path: 'product/:productId',
      element: <Product />,
    },
    {
      path: 'product-search',
      element: <SearchProduct />,
    },
    {
      path: 'our-story',
      element: <OurStory />,
    },

    {
      path: 'cart',
      element: <Cart />,
    },
    {
      path: 'select-address',
      element: <AddressSelect />,
    },
    {
      path: 'select-payment',
      element: <PaymentSelect />,
    },
    {
      path: 'review-order',
      element: <OrderReview />,
    },
    {
      path: 'place-order',
      element: <OrderPlace />,
    },
    {
      path: 'product-catalogue/*',
      element: <ProductCatalogue />,
    },
    {
      path: 'collections',
      children: [
        {
          index: true,
          element: <Collections />,
        },
        {
          path: ':collId',
          element: <CollectionDetails />,
        },
      ],
    },

    {
      path: 'moodboards',
      children: [
        {
          index: true,
          element: <Moodboards />,
        },

        {
          path: 'share-link/:mbdId',
          element: <SharedMbd />,
        },
        {
          path: 'search',
          element: <SearchMB />,
        },
        {
          path: 'create-new',
          element: <CreateMB />,
        },
        {
          path: 'edit/:mbId',
          element: <CreateMB edit />,
        },
        {
          path: ':keyTab',
          element: <Moodboards />,
        },
        {
          path: ':keyTab/:category',
          element: <CategoryMB />,
        },
      ],
    },
    {
      path: 'portal',
      children: [
        {
          index: true,
          element: <UserPortal />,
        },
        {
          path: ':keyTab/*',
          element: <UserPortal />,
        },
      ],
    },
    // {
    //   path: 'PrivacyPolicy',
    //   element: <PrivacyPolicy />,
    // },
    {
      path: 'terms&Conditions',
      element: <TermsAndConditions />,
    },
    {
      path: 'checkout-redirect',
      element: <CheckoutRedirect />,
    },
  ])

  return routes
}

export default RoutesWrapper
