import { Avatar, Button, Dropdown, Menu } from 'antd'
import { t } from 'i18next'
import { css } from '@emotion/css'
import { Link } from 'react-router-dom'
import useAuth from 'services/api/auth/useAuth'
import useFirebase from 'hooks/useFirebase'
import { useSelector } from 'react-redux'

function UserMenu({ light }) {
  const DropdownStyles = css`
    width: 3.5rem;
    height: 3.5rem;
    background-color: ${light ? '#fff' : '#000'};
    cursor: pointer;

    span {
      font-family: 'dream orphans', sans-serif;
      letter-spacing: 1px;
      font-size: 1.4rem;
      line-height: 2.5;
      text-transform: uppercase;
      color: ${light ? '#000' : '#fff'};
    }
  `

  const user = useSelector(s => s.entities?.user?.currentUser)

  // console.log('UserMenu ~ user', user)
  const { signout, authLoading } = useAuth()
  const { signOutFirebase } = useFirebase()

  const userAvatarName =
    (user.first_name.trim()[0] || '') + (user.last_name.trim()[0] || '')

  const MenuStyles = css`
    width: 24rem;
    li {
      padding: 0.8rem 2rem;
      margin: 1rem 0;
      span {
        font-family: 'dream orphans', sans-serif;
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: capitalize;
        line-height: 1.4;
        letter-spacing: 1px;
        color: #000;
      }

      .logout-btn {
        padding: 0;
        span {
          color: #ff4d4f;
        }
      }
    }
  `

  const menu = (
    <Menu
      className={MenuStyles}
      items={[
        {
          label: <Link to="/portal/profile"> {t('profile')}</Link>,
          key: 'profile',
        },
        {
          label: <Link to="/portal/orders">{t('orders')}</Link>,
          key: 'orders',
        },
        // {
        //   label: <Link to="/portal/payments">{t('payments')}</Link>,
        //   key: 'payments',
        // },
        {
          label: (
            <Link to="/portal/saved-addresses"> {t('saved addresses')}</Link>
          ),
          key: 'saved-addresses',
        },
        {
          label: (
            <Link to="/portal/my-mood-boards"> {t('my mood boards')}</Link>
          ),
          key: 'my-moodboards',
        },
        {
          label: (
            <Link to="/portal/my-favorite-list"> {t('my favorite list')}</Link>
          ),
          key: 'my-favorite-list',
        },
        {
          label: (
            <Button
              type="link center logout-btn"
              block
              danger
              onClick={() => {
                signout()
                signOutFirebase()
              }}
              loading={authLoading}
            >
              {t('Logout')}
            </Button>
          ),
          key: 'Logout',
        },
      ]}
    />
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      placement="bottomRight"
      arrow
      className={DropdownStyles}
    >
      {/* <Tooltip title={t('profile')} placement="bottomRight"> */}
      <Avatar>{userAvatarName}</Avatar>
      {/* </Tooltip> */}
    </Dropdown>
  )
}
export default UserMenu
