import { useState } from 'react'

const { createContext } = require('react')

export const servicesContext = createContext({})

function ServicesContext({ children }) {
  const [shoppingPath, setShoppingPath] = useState('/')

  return (
    <servicesContext.Provider value={{ shoppingPath, setShoppingPath }}>
      {children}
    </servicesContext.Provider>
  )
}

export default ServicesContext
