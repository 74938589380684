import { useEffect, useState } from 'react'

let setFooterLayout
let setHeaderLayout
let resetLayout

const initLayout = {
  visible: true,
  props: null,
}

function LayoutConfig({
  headerElement: HeaderElement,
  footerElement: FooterElement,
  htmlLang = 'en',
  children,
}) {
  const [header, setHeader] = useState(initLayout)
  const [footer, setFooter] = useState(initLayout)
  setHeaderLayout = setHeader
  setFooterLayout = setFooter
  resetLayout = () => {
    setHeader(initLayout)
    setFooter(initLayout)
  }

  useEffect(() => {
    document.documentElement.lang = htmlLang
  }, [htmlLang])

  return (
    <>
      {header.visible && <HeaderElement {...header.props} />}
      <main style={{ minHeight: '75vh' }}>{children}</main>
      {footer.visible && <FooterElement {...footer.props} />}
    </>
  )
}

export { LayoutConfig, setHeaderLayout, setFooterLayout, resetLayout }
export default LayoutConfig

// example
// setHeaderLayout({ visible: true, props: { light: true } })
