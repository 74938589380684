import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { queryKeys } from 'services/api/react-query/queryKeys'
import useApi from 'services/api/useApi'

function useCartProdList() {
  const api = useApi()
  const user = useSelector(s => s.entities?.user?.currentUser)

  const http = async () => {
    const res = await api.get('cart/view')
    return res
  }
  const { data } = useQuery([queryKeys.cartProductList], http, {
    enabled: !!user,
    staleTime: 1000 * 60 * 10,
  })
  // console.log('useCartProdList ~ data', data)

  const cartItems = data?.data?.cart?.items
  const itemsCount = data?.item
  const total = data?.data?.cart?.total

  return { cartItems, itemsCount, total }
}

export default useCartProdList
