import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "register",
  initialState: {},
  reducers: {
    // setUserRegisterData: (user, action) => {
    //   const { type, data } = action.payload;
    //   user[type] = data;
    //   // console.log(data, "sdfasdasdf")
    //   if (data?.step === 1) {
    //     user.showSalesCommentiy = true;
    //   } else {
    //     user.showSalesCommentiy = false;
    //   }
    // },
    // setUserRegisterStep: (user, action) => {
    //   const { type, step } = action.payload;
    //   user[type].step = step;
    //   if (step === 1) {
    //     user.showSalesCommentiy = true;
    //   } else {
    //     user.showSalesCommentiy = false;
    //   }
    // },
    // setSalesCommentiy: (user, action) => {
    //   user.showSalesCommentiy = action.payload;
    // },
    // resetRejster: (user, action) => {
    //   return {
    //     sales: {
    //       step: 1,
    //       step1: null,
    //     },
    //     recruiter: null,
    //     brokerage: null,
    //     developer: null,
    //     freelancer: null,
    //     showSalesCommentiy: true,
    //   };
    // },
  },
});

// export const {

// } = slice.actions;

export default slice.reducer;
