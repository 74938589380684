const TOKEN_LOCALSTORAGE_KEY = 'access-token'

export function getStoredToken() {
  const storedToken = localStorage.getItem(TOKEN_LOCALSTORAGE_KEY)
  return storedToken
}

export function setStoredToken(token) {
  localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token)
}

export function clearStoredToken() {
  localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY)
}
