import { QueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

const onErrorHandler = error => {
  // console.log('onError Global', error)
  const code = error?.response?.status

  if (code === 401) {
    localStorage.clear()
    window.location.replace('/signin')
    return null
  }

  if (code >= 400 || code < 500) {
    toast.error(error.message)
  } else {
    toast.error(
      'Sorry. something went wrong .A team of highly trained developers has been dispatched to deal with this situation!'
      // { autoClose: 30000, hideProgressBar: true }
    )
  }
  return error
}

export default new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 1000 * 60 * 2, // means queries will not refetch their data as often
      // cacheTime: 1000 * 60 * 7,
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      retry: 2,
      onSuccess: res => {
        // console.log('onSuccess Global queries', res)
        if (res?.code !== 200) {
          toast(res?.message)
        }
        if (res?.validation) {
          res.validation?.forEach(err => {
            toast.error(err)
          })
        }
      },
      onError: onErrorHandler,
      networkMode: 'always',
    },
    mutations: {
      onSuccess: res => {
        // console.log('onSuccess Global mutations', res)
        if (res?.code === 200) {
          toast.success(res?.message)
        }
        if (res?.validation) {
          res.validation?.forEach(err => {
            toast.error(err)
          })
        }
      },
      onError: onErrorHandler,
      networkMode: 'always',
    },
  },
})
