import { Col, Row } from 'antd'
import logo_gold from '../../assets/images/logo gold.png'
import fb_img from '../../assets/images/icons/fb.svg'
import ig_img from '../../assets/images/icons/ig.svg'
import li_img from '../../assets/images/icons/li.svg'
import phone_img from '../../assets/images/icons/phone.svg'
import msg_img from '../../assets/images/icons/msg.svg'
import styles from './Footer.module.scss'
import { t } from 'i18next'

function Footer() {
  return (
    <div className={styles.footer_wrapper}>
      <div className="container">
        <div className={styles.footer_inner}>
          <div className={styles.find_us_wrapper}>
            <div className={styles.title}>{t('Where to find us')}</div>
            <div className={styles.items_list}>
              <Row gutter={[58, 58]} justify="center">
                <Col>
                  <div className={styles.single_item}>
                    <p>
                      24 Al Ryad Street (off Shehab Street), Al-Mohandeseen,
                      Giza.
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className={styles.single_item}>
                    <p>Arkan Plaza Extension, Al Shiekh Zayed, Giza.</p>
                  </div>
                </Col>
                <Col>
                  <div className={styles.single_item}>
                    <p>Arabella Plaza Mall (in front of Arabella Compound)</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.contact_us_wrapper}>
            <div className={styles.brand}>
              <img src={logo_gold} alt="naseej" />
            </div>
            <div className={styles.items_list}>
              <Row gutter={[58, 58]} justify="center">
                <Col>
                  <div className={styles.single_item}>
                    <img src={phone_img} width={24} height={24} alt="phone" />
                    <a href="tel:01061266665">01061266665</a>
                  </div>
                </Col>
                <Col>
                  <div className={styles.single_item}>
                    <img src={msg_img} width={24} height={24} alt="mail" />
                    <a href="malito:">info@naseejfabrics.com</a>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className={styles.follow_us_wrapper}>
            <div className={styles.title}>{t('Follow us')}</div>
            <ul className={styles.items_list}>
              <li>
                <a
                  href="https://www.facebook.com/naseejfabrics"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fb_img} width={24} height={24} alt="facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/naseejfabrics/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ig_img} width={24} height={24} alt="instagram" />
                </a>
              </li>
              {/* <li>
                <a href="/"  target='_blank' rel="noreferrer">
                  <img src={li_img} width={24} height={24} alt="linked in" />
                </a>
              </li> */}
            </ul>
          </div>
          <div className={styles.extra_wrapper}>
            <p>
              {t(`Tax registration number`)}: {t(`521-378-346`)}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
