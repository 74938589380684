import styles from './OwnOverlay.module.scss'
import cls from 'classnames'

function OwnOverlay({ className, visible, onClick }) {
  return (
    <div
      className={cls(styles.overlay_wrapper, className)}
      style={{ display: visible ? 'block' : 'none' }}
      onClick={onClick}
    />
  )
}

export default OwnOverlay
