import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    metaData: null,
    subscriptionPackge: null,
    userAddress: [],
    actionAfterLogin: null,
    loading: false,
  },
  reducers: {
    setCurrentUser: (user, action) => {
      user.currentUser = action.payload
    },
    setMetaData: (user, action) => {
      user.metaData = action.payload
    },
    setCustomerAddresses: (user, action) => {
      user.userAddress = action.payload
    },
    setUserSubscriptionPackge: (user, action) => {
      user.subscriptionPackge = action.payload
    },
    setActionAfterLogin: (user, { payload }) => {
      user.actionAfterLogin = payload
    },
    // handleActionAfterLogin: (user, { payload }) => {
    //   if (user.actionAfterLogin) {
    //     user.actionAfterLogin()
    //   }
    //   user.actionAfterLogin = null
    // },
  },
})

export const {
  setCurrentUser,
  setMetaData,
  setCustomerAddresses,
  setUserSubscriptionPackge,
  // handleActionAfterLogin,
  setActionAfterLogin,
} = slice.actions

export default slice.reducer
